import React from 'react';
import { Link } from 'gatsby';
import { Frown } from 'react-feather';

import SEO from '../components/seo';

import styles from '../styles/404.module.scss';

const NotFoundPage = () => (
    <>
        <SEO
            title='404: Not found'
        />
        <div className={styles.main}>
            <section className={styles.content}>
                <Frown
                    size={64}
                    className={styles.icon}
                />
                <h1>404 - NOT FOUND</h1>
                <p>Oh no! You just found a page that doesn&#39;t exist...</p>
                <div className={styles.links}>
                    <p>Maybe you were looking for one of these?</p>
                    <ul>
                        <li>
                            <Link to='/'>Home Page</Link>
                        </li>
                        <li>
                            <Link to='/monthly-jewelry-subscription/'>Become a Member</Link>
                        </li>
                        <li>
                            <Link to='/store/'>Shop Our Store</Link>
                        </li>
                        <li>
                            <Link to='/cart/'>View Your Cart</Link>
                        </li>
                        <li>
                            <Link to='/about-us/'>About Us</Link>
                        </li>
                        <li>
                            <Link to='/#how_it_works'>How It Works</Link>
                        </li>
                        <li>
                            <Link to='/our-partners/'>Our Partners</Link>
                        </li>
                        <li>
                            <Link to='/#faq'>FAQ</Link>
                        </li>
                        <li>
                            <Link
                                to='/'
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.zE('webWidget', 'open');
                                    return false;
                                }}
                            >
                                Help
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    </>
)

export default NotFoundPage;
